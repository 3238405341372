@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

@layer base {
  html {
    @apply text-slate-800;
    @apply md:text-xl;
    @apply text-base;
  }
}
@font-face {
  font-family: RobotoCondensed;
  src: url("./fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: Giants;
  src: url("./fonts/Giants.ttf");
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2 {
  font-family: Giants;
}

a {
  text-decoration: none;
}

header a:hover {
  color: #99f6e4;
}

header a:active {
  color: #115e59;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.item-rotation {
  bottom: 50%;
  right: 0;
  z-index: 0;
  position: absolute;
  animation: spinAround 24s linear infinite;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg) translate(250px) rotate(360deg);
  }
  to {
    transform: rotate(360deg) translate(250px) rotate(0deg);
  }
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 4px;
  display: inline-block;
  transform-origin: bottom right;
  padding: 24px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.title {
  animation: 0.7s ease-in title;
}

@keyframes title {
  0% {
    scale: 0.1;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

/* Overrides for react-image-gallery */
.image-gallery-slide img {
  aspect-ratio: 16 / 9;
}
